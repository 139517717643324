@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-range-input;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

// Carousel;
@import "components/owl.carousel.scss";
//customized elements in _core.scss";

@font-face {
    font-family: BeachTownScript;
    src: url(../fonts/BeachTownScript.ttf);
}



body{
    background: white;//url(../img/tempmobile.jpg) top center no-repeat;
    @include breakpoint(medium up) { 
        background: white;//url(../img/tempback.jpg) top center no-repeat;
    }
}

p{
    font-size:1.125rem;
    color: $dark-gray;
    line-height:1.75rem;


}

em.teal-text {
    color:#6EC6BD;
}


.grid-container.fluid.p0{padding:0;}
.grid-padding-x > .cell.p0{padding:0;}
.p0{padding:0;}
.plh{padding-left:.5rem;}
.pl1{padding-left:1rem;}
.pr1{padding-right:1rem;}
.prh{padding-right:.5rem;}
.pt1{padding-top:1rem;}
.pt2{padding-top:2rem;}
.pt3{padding-top:3rem;}
.pt4{padding-top:4rem;}
.pt5{padding-top:5rem;}
.pt6{padding-top:6rem;}
.pb1{padding-bottom:1rem;}
.pb2{padding-bottom:2rem;}
.pb3{padding-bottom:3rem;}
.pb4{padding-bottom:4rem;}
.pb5{padding-bottom:5rem;}
.pb6{padding-bottom:6rem;}
.pb3m{
    padding-bottom:3rem;
    @include breakpoint(small only) {
        padding-bottom:1.5rem;
    }
}
.pl-sm-1{
    @include breakpoint(small only) {
        padding-left:1rem;
    }
}
.mt1{margin-top:1rem;}
.mt2{margin-top:2rem;}
.mt3{margin-top:3rem;}
.mt4{margin-top:4rem;}
.mt-overlap{margin-top:-2.7rem;}
.mb2{margin-bottom:2rem;}
.mb3{margin-bottom:3rem;}
.mb4{margin-bottom:4rem;}
.nobr{display:inline-block;}
.bgyellow{background:$primary-color;}
.bgteal{background:$secondary-color;}
.bgpurple{background:$purple;}
.bgpink{
    background:$pink;

    h3 {
        color: #fff;
    }
}
.bgtan{background:$tan;}
.bgblack{background:$black;}


.fitwidth{
    max-width:100%;
}

@mixin myshadow {
    -webkit-box-shadow: 0px 12px 11px 0px rgba(0,0,0,0.18); 
    box-shadow: 0px 12px 11px 0px rgba(0,0,0,0.18);
}

.shadow{
    @include myshadow;
    border-radius:6px;
}
.grid-x.pad{ 
    padding-left:20px;
    padding-right:20px;
    @include breakpoint(small only) { 
        padding-left:0px;
        padding-right:0px;
    }
}

.underline{
    text-decoration: underline;
}

.clear-both{
    clear:both;
}

.overflow-hidden{
    overflow:hidden;
}

.text-spc-cr{
    text-align:right;
    @include breakpoint(small only) {
        text-align:center;
    }
}

/*-- Navigation -------------------------------------*/
.no-js {
    @include breakpoint(small only) {
      .top-bar {
        display: none;
      }
    }
  
    @include breakpoint(medium) {
      .title-bar {
        display: none;
      }
    }
}

.top-bar {
    @include breakpoint(small only) { 
        padding-top:1rem;
    }

    .top-bar-left{
        width:100%;
        text-align:center;
        img{
            max-height:90px;
        }
        @include breakpoint(medium up) { 
            width:25%;
            text-align:left;
            img{
                max-height:119px;
            }
        }
     }

    .top-bar-right{
        @include breakpoint(medium up) { 
            width:75%;
            text-align:right;
            max-width:920px;
        }
    }

    
    

    .menu{
        background: $primary-color;
        position:absolute;
        top:60px; width:100%; z-index:100;
        padding-top:1rem; padding-bottom:1rem;
        @include myshadow;

        li{
            display:flex;
            align-items:center;
            justify-content:center;
            width:100%;

            a{
               font-size:1rem;
               font-weight: 600;
               color:$black;
               width:100%;
               text-align:center;
               padding-top:1rem;
               padding-bottom:1rem;
            }
            a.isactive{
                color:$primary-color;
                @include breakpoint(small only) { 
                    background:$black;
                }
            }
        }

        @include breakpoint(medium up) { 
            flex-wrap: nowrap;
            background:none;
            position:static;
            -webkit-box-shadow: none; 
            box-shadow: none;
            justify-content:space-between;

            li{
                text-align:center;
                align-items:flex-start;
                justify-content:flex-end;
                padding-right:0;
                width:auto;
    
                a{
                    font-size:.85rem;
                    padding-right:0;
                    text-align:center;
                    width:auto;
                }

                
            }
            

        }
    }

}



.title-bar{
    height:60px;
    background:none;
    background:$secondary-color;

    .menu-icon{
        @include hamburger($white, $tan, 44px, 29px, 5px, 3);
    }
}



/*---- buttons -------------------------------*/
.prpill{
    background:$primary-color;
    color:$black;
    font-size:22px;
    font-weight:600;
    padding:23px 40px 25px;
    border-radius:40px;
    text-transform:uppercase;
    @include myshadow;
    @include breakpoint(small only) {
        font-size:1.125rem;
        padding:20px 40px 21px;
    }
}

.blpill{
    background:$black; 
    color:$white;
    font-size:18px;
    font-weight:600;
    border-radius:34px;
    padding:16px 37px 18px;
    text-transform:uppercase;
    @include myshadow;
    @include breakpoint(small only) {
        font-size:1rem;
        padding:20px 40px 21px;
    }
}

.socialpill{
    background:#197CF2;
    color:$white;
    font-size:18px;
    font-weight:600;
    padding:18px 38px 20px;
    border-radius:34px;
    @include myshadow;
    @include breakpoint(small only) {
        font-size:1.125rem;
        padding:20px 40px 21px;
    }
    span{
        padding-left:27px;
    }
}
.socialpill{
    &.facebook, &.facebookStory{
        span{
            background:url(../img/about/facebook.svg) center left no-repeat;
        }

    }
}
.socialpill.facebookKalei{
    span{
        background:url(../img/about/facebook.svg) center left no-repeat;
    }
}
.socialpill.twitter{
    background:#1D9BF0;
    span{
        padding-left:31px;
        background:url(../img/about/twitter.svg) center left no-repeat;
    }
}

.button.slideshow{
    background:$light-gray;
    width:18px;
    height:18px;
    border-radius:50%;
    margin:.85rem;
    padding:0;
}
.button.slideshow.isactive{
    background:$primary-color;
}

.button.uparrow{
    background:url(../img/uparrow.svg) center center no-repeat;
    width:52px; height:52px;
    background-size:contain;
}

//arrows
.leftarr, .rightarr{
    position:relative;
    border-radius:50%;
    background:url(../img/icon-arrow.svg) center center no-repeat;
    background-size:contain;
    width:100%;
    aspect-ratio: 1 / 1;
    // height: 0;
    // padding-bottom: 90%;
    max-width:84px;
    max-width:84px;
}
.rightarr{
    transform:rotate(180deg);
}

.script{
    font-family:BeachTownScript;
    color:$secondary-color;
    font-size:7.375rem;
    text-align:right;
    margin-top:-4rem;
    @include breakpoint(large) {
        padding-right:3rem;
    }
    @include breakpoint(small only) {
        font-size:5.375rem;
        margin-top:-3rem;
    }
    &.cen{
        text-align:center;
        padding-right:0;
    }
}

.subtitle{
    font-size:1.125rem;
    font-weight:600;
    color:$secondary-color;
}
.circleHeart{
    width:115px;
    @include breakpoint(small only) {
        width:87px;
    }
}

.topcontent{
    font-size:1.5625rem;
    font-weight:600;
    color:$purple;
    line-height:1.12;
    @include breakpoint(small only) {
        font-size:1.25rem;
    }
    em{
        font-weight:600;
    }
}
.extlink{
    cursor:pointer;
}


sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.5em;
    font-size:60%;
}
sub { 
    top: 0.4em; 
}
/*---- under photo disclaimer text -------------------------------*/
div.stock{
    font-size:.8rem;
    color:$dark-gray;
    width:100%;
    padding:0; 
    margin:0;//.5rem 0 0;
    display:flex;
    font-style: italic;

    span{
        max-width:318px;
    }
    @include breakpoint(small only) { 
        font-size:11px;
    }
}
div.stock.right{
    text-align:right;
    justify-content:flex-end;
    @include breakpoint(small only) { 
        text-align:left;
        justify-content:flex-start;
    }
}
span.stock.left{
    text-align:left;
}
  
// Content 
/*---general blob info-----------*/
.blob{
    position:absolute;
    pointer-events:none;
    z-index:-1;
    background-repeat:no-repeat;
    background-size:contain;
}
.blob.purple{
    background-image: url(../img/bubble-purple1.svg);
    width:494px;
    height:616px;
    top:0px;
    @include breakpoint(small only) {
        left: 93vw;
        width:285px;
        height:348px;
        top:40px;
    }
    @include breakpoint(medium ) {
        left:95vw;
    }

    @include breakpoint(large ) {
        left:94vw;
    }
    @include breakpoint(xlarge up) {
        left:93vw;
    }
}

.blob.orange{
    background-image: url(../img/bubble-orange.svg);
    width:489px;
    height:675px;
    top:300px;
    @include breakpoint(small only) {
        top:10%;
        right:90vw;
        width: 215px;
        height:296px;
    }
    @include breakpoint(medium ) {
        right:96vw;
    }
    @include breakpoint(large ) {
        right:95vw;
    }
    @include breakpoint(xlarge ) {
        right:94vw;
    }
    @include breakpoint(xxlarge ) {
        right:93vw;
    }
}

.blob.teal{
    background-image: url(../img/bubble-teal.svg);
    width:488px;
    height:673px;
    top:26%;
    @include breakpoint(small only) {
        left: 93vw;
        width:378px;
        height:521px;
        top:32%;
    }
    @include breakpoint(medium ) {
        left:94vw;
    }

    @include breakpoint(large ) {
        left:93vw;
    }
    @include breakpoint(xlarge up) {
        left:92vw;
    }
}
.blob.purple2{
    background-image: url(../img/bubble-purple2.svg);
    width:627px;
    height:627px;
    bottom:32%;
    @include breakpoint(small only) {
        bottom:32%;
        right:90vw;
        width: 360px;
        height:360px;
    }
    @include breakpoint(medium ) {
        right:95vw;
    }
    @include breakpoint(large ) {
        right:94vw;
    }
    @include breakpoint(xlarge ) {
        right:93vw;
    }
    @include breakpoint(xxlarge ) {
        right:92vw;
    }
}

.blob.yellow{
    background-image: url(../img/bubble-yellow.svg);
    width:568px;
    height:708px;
    bottom:10%;
    @include breakpoint(small only) {
        bottom:12%;
        left: 93vw;
        width:313px;
        height:393px;
    }
    @include breakpoint(medium ) {
        left:94vw;
    }

    @include breakpoint(large ) {
        left:93vw;
    }
    @include breakpoint(xlarge up) {
        left:92vw;
    }
}



.cardholder{
    display:flex;
    flex-flow:row wrap;
    justify-content:center;
    border:none;
}

h1.page{
    padding-top:8rem;
    margin-bottom:2rem;
    @include breakpoint(small only) {
        padding-top:3rem;
        font-size:2rem;
        margin-bottom:1rem;
    }
}

.max998{
    max-width:998px;
    margin:auto;
    @include breakpoint(medium down) {
        padding:0 1.5rem;
    }
}
p:not(.topcontent){
    @include breakpoint(small only) {
        font-size:1rem;
        line-height:1.625rem;
    }
}
.primary{
    color:$primary-color;
}
.purple{
    color:$purple;
}
.pagetop{
    position:relative;
    margin-top:-80px;
    padding-top:80px;
    overflow:hidden;
    padding:0;
}
.arrowdownpage{
    position:relative;
    margin:auto;
    width:98px;
    height:174px;
    background:url(../img/line2.svg) center -70px no-repeat;

    a{
        position:absolute;
        left:0;
        bottom:0;
    }

}

a.button.downarrow{
    width:98px;
    height:98px;
    background:url(../img/circlednarrow.svg) center center no-repeat;
}

.heartdown{
    position:relative;
    margin:auto;
    width:115px;
    height:324px;
    background:url(../img/about/line2.svg) center -4px no-repeat;
    text-align:center;
    img{
        margin: 2rem auto;
    }
    a{
        position:absolute;
        left:0;
        bottom:0;
        @include breakpoint(small only) {
            left: calc(50% - 44);
            bottom:0;
        }
    }
    @include breakpoint(small only) {
        height:620px;
        width:100%;
        background:url(../img/about/line3.svg) center 160px no-repeat;
    }
}

a.button.heart{
    width:115px;
    height:115px;
    background:url(../img/icon-circleHeart.svg) center center no-repeat;
    background-size:100%;
    @include breakpoint(small only) {
        width:87px;
        height:87px;
        left: calc(50% - 44px);
        bottom:0;
    }
}

.endlining{
    position:relative;
    height:324px;
    background:url(../img/stories/line4.svg) center top no-repeat;
    @include breakpoint(small only) {
        height:245px;
        width:87px;
        background-size:3px 164px;
    }
    .endjourney{
        height:115px;
        position:absolute;
        bottom:0;
        left:0;
        @include breakpoint(small only) {
            height:87px;
            background-size:87px 87px;
        }
    }
}

.vibox.end{
    background:$purple;
    color:$white;
    padding:5rem 6rem;
    @include breakpoint(small only) {
        padding:3rem 1.5rem;
    }
    margin-bottom:3rem;
}


/*Home top -----------------------*/

.hometop{
    min-height:100vh;
    position:relative;
    margin-top:-80px;
    padding-top:80px;
    padding-bottom:250px;
    background: url(../img/circHeart.svg) center bottom no-repeat;
    background-size:115px 273px;
    div.hearticon{
        position:absolute;
        bottom:158px;
        left:calc(50% - 58px);
        width:115px;
        height:115px;
        background: none;
        a{
            display:block;
            width:100%;
            height:100%;
            background:none;
        }
        @include breakpoint(small only) {
            bottom:120px;
            left:calc(50% - 43px);
            width:87px;
            height:87px;
        }
    }

    @include breakpoint(small only) {
        padding-top:64px;
        padding-bottom:230px;
        background-size:87px 207px
    }
    overflow:hidden;
    h1{
        line-height: 1.14;
        @include breakpoint(large only) {
            font-size:calc(100vw/21);
        }
        @include breakpoint(medium only) {
            font-size:calc(100vw/23);
        }
        @include breakpoint(small only) {
            //text-align:center;
        }

    }
    h3{
        @include breakpoint(large only) {
            font-size:calc(100vw/36);
        }
        @include breakpoint(medium only) {
            font-size:calc(100vw/36);
        }
        @include breakpoint(small only) {
            //text-align:center;
            font-size:1.4375rem;
        }
        margin-bottom:0;
        padding-bottom:0;
    }
    p{
        @include breakpoint(large) {
            padding-right:3rem;
        }
        @include breakpoint(small only) {
            font-size:1rem;
        }
    }


    .prpill{
        @include breakpoint(small only) {
            margin-bottom:3rem;
        }
    }
    .blob.orange{
        @include breakpoint(small only) {
            top:auto;
            bottom:0;
        }
    }
    
}



/*home stories-----------------------*/
.kaleidoscopeshare{
    padding-top:7rem;
    position: relative;


}
 .seeline{
        background:url(../img/stories/line3.svg) center bottom no-repeat;
        padding:0rem 0 7rem;
        @include breakpoint(small only) {
            padding:0rem 0 6rem;
            background-size:3px 620px;
        }
    }

.yrstories{
    padding-top:7rem;
    position:relative;
    p{
        color:#333;
    }

    

    .button.slideshow.isactive{
        background:$black;
    }


    .storycard{
        /*width:319px;
        height:537px;*/
        margin:0 1rem 1.5rem;
        position:relative;

        .text{
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            padding:1.125rem 1.125rem 3.5rem;
            color:$white;
        }
        h4{
            font-size:1.25rem;
            margin:0 0 -.25rem; padding:0;
        }
        img{
            border-radius:6px;
        }
    }
    
}
.yrstories::after{
    content:"";
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:70%;
    z-index:-1;
    background:$primary-color;
    
    
}

/*home types-----------------------*/
.typesdep {
    overflow:hidden;
    position:relative;
    @include breakpoint(small only) {
        background: url(../img/home/journey-types-sm.svg) left top no-repeat;
        background-size:50% 100%;
    }
    .tjourney{
        padding-top:22rem;
        padding-bottom: 25rem;
        background: url(../img/home/journey-types.svg) center center no-repeat;
        background-size:100% 100%;
        @include breakpoint(small only) {
            padding-top:14rem;
            padding-bottom: 18rem;
            background: none;
        }
    }
   
    .subtitle{
        @include breakpoint(small only) {
            display:block;
            margin-top:2rem;
        }
    }
    img{
        border-radius:6px;
        @include myshadow;

    }

    .button{
        @include breakpoint(small only) {
            margin-top:2rem;
        }
    }


    .blob.teal{
        top:0px;
        @include breakpoint(small only) {
            top:40px;
        }
    }
    .blob.purple2{
        bottom:-200px;
        @include breakpoint(small only) {
            bottom:0;
        }
    }
}

/*home treat-----------------------*/

.treatdep {

    padding-top:9rem;
    padding-bottom:9rem;
    h4{
        @include breakpoint(large only) {
            //font-size:calc(100vw/43);
        }
        @include breakpoint(medium down) {
            font-size:30px;
        }
        @include breakpoint(small only) {
            line-height: 1.47;
            margin-bottom:1rem;
        }

    }
    img{
        @include myshadow;
        border-radius:6px;
    }
    .subtitle{
        @include breakpoint(small only) {
            display:block;
            margin-top:2rem;
        }
    }
    .button{
        @include breakpoint(small only) {
            margin-top:2rem;
        }
    }
}

/*home resources-----------------------*/

.resources {

    overflow:hidden;
    position:relative;
    .targ{
        position:relative;
        z-index:2;
    }
    .targ::before{
        content:"";
        width:97px;
        height:97px;
        position:absolute;
        top:0px;
        left:-114px;
        background:white url(../img/home/icon-bullseye.svg) center center no-repeat;
        background-size:contain;
        @include breakpoint(large up) {
            left: calc(-12*100vw/1362 - 100px);
        }
        @include breakpoint(medium only) {
            left:-92px;
        }
        @include breakpoint(small only) {
            background:none;
        }
        z-index:-1;
    }

    background:none;
    @include breakpoint(small only){
        background-image: url(../img/home/journey-res1-sm.svg);
        background-repeat:no-repeat; 
        background-size:100% 100%;
        background-position: 0 9rem;

        
    }
    .rjourney{
        //overflow:hidden;
        padding-top:9rem;
        padding-bottom: 20rem;
        background-image: url(../img/home/journey-res1.svg);
        background-repeat:no-repeat; 
        background-size:100% 100%;
        @include breakpoint(small only) {
            background:none;
        }
        @include breakpoint(medium only) {
            background-position: center 7rem;
        }
        @include breakpoint(large only) {
            background-position: center 9rem;
        }
        @include breakpoint(xlarge up) {
            background-position: center 12rem;
        }
    }
    h4{
        @include breakpoint(large only) {
            //font-size:calc(100vw/43);
        }
        @include breakpoint(medium down) {
            font-size:30px;
        }
        @include breakpoint(small only) {
            line-height: 1.47;
            margin-bottom:1rem;
        }

    }

    .button{
        @include breakpoint(small only) {
            margin-top:2rem;
        }
    }

    .home-resource-carousel{
        @include breakpoint(medium up) {
            width:100%;
            margin:0 auto;
    
            .owl-stage-outer{
                overflow:visible;
            }
        }
    }
    

    .homerescard{
        //width:434px !important;
        //@include breakpoint(small only) {
           //width:342px !important;
        //}
        //width:calc(33% - 2rem);
        //margin: 0 1rem;
        vertical-align:top;
        display:inline-block;
        a{
            display:inline-block;
            color:$black;
        }
        @include breakpoint(small only) {
           width:calc(100% - 2rem);
        }
    }
    .featres{
        position:relative;
        text-align:center;
        .fres{
            border-radius:6px; 
            overflow:hidden; 
            @include myshadow;
            //width:434px;
            aspect-ratio: 4 / 5;
            display:flex;
            justify-content: center;
            align-items: center;
            //@include breakpoint(small only) {
                //width:342px !important;
            //}

            img{
                width:73%;
            }
        }
        .freslabel{
            background:$white;
            position:absolute;
            top:1.2rem;
            left:0;
            color:$black;
            font-size:.9375rem;
            text-transform:uppercase;
            padding:.75rem 2.25rem;
            max-width:75%;
            border-top-right-radius:1.4rem 50%;
            border-bottom-right-radius:1.4rem 50%;

            &.rd2{
                border-top-right-radius:2rem 50%;
                border-bottom-right-radius:2rem 50%;
    
            }
        }
        .frestag{
            color:$white;
            font-size:1.25rem;
            font-weight:700;
            padding:20px;
            display:inline-block; min-width:85%;
            position:relative;
            top:-2.25rem;
            margin-bottom:-2rem;
            @include breakpoint(medium down) {
                font-size:1rem;
            }
        }
        .fcapt{
            font-size:1.375rem;
            font-weight:700;
            margin-top:.7rem;
            @include breakpoint(medium down) {
                font-size:1.125rem;
            }

        }
    }

    .featres.infographic{
        .fres{
            background:#E8BEC1;
        }
        .frestag{
            background:$pink;
        }
    }
    .featres.step-by-step{
        .fres{
            background:#E9DCEB;
        }
        .frestag{
            background:$purple;
        }
    }

    span.stock{
        margin-top:-2rem;
        @include breakpoint(small only) {
            margin-top:-1rem;
        }
    }
    .subtitle{
        @include breakpoint(small only) {
            display:block;
            margin-top:2rem;
        }
    }

    .blob.yellow{
        bottom:0px;
        @include breakpoint(small only) {
            bottom:0px;
        }
    }
    //z-index:-2;
}

.resources::before{
    @include breakpoint(small only) {
        content:"";
        width:97px;
        height:97px;
        position:absolute;
        top:9.5rem;
        left:-30px;
        background:white url(../img/home/icon-bullseye.svg) center center no-repeat;
        background-size:contain;
        z-index:-1;
    }
}
.endjourney{
    height:160px;
    background:url(../img/resources/icon-circleHeart.svg) top center no-repeat;
    background-size:115px 115px;
}


/* about page-----------------------*/

.aboutpage {
    .blob.teal{
        top:40%;
    }
    .blob.purple2{
        bottom:7%;
    }

    position:relative;
    margin-top:-80px;
    padding-top:80px;
    overflow:hidden;

    h1{
        padding-top:8rem;
        margin-bottom:0rem;
        @include breakpoint(small only) {
            padding-top:3rem;
            font-size:2rem;
        }
    }

    .script{
        font-size:11.9375rem;
        text-align:center;
        margin:-5rem 0 -2rem;
        text-align: center;
        padding:0;
        @include breakpoint(medium only) {
            font-size:calc(100vw/7);
            margin:-4rem 0 -1.5rem;
            padding:0;
        }
        @include breakpoint(small only) {
            font-size:6.5rem;
            margin:-3rem 0 -1rem;
            padding:0;
           // margin-top:-3rem;
        }
    }

    .boldcap{
        text-transform:uppercase;
        font-size:2.0rem;
        font-weight:800;
        color:$dark-gray;
        @include breakpoint(small only) {
            font-size:1.06rem;
        }
    }
    .thintall{
        font-size:4rem;
        color:$dark-gray;
        font-weight:300;
        @include breakpoint(small only) {
            font-size:2rem;
        }
    }
    .vibox{
        background:$purple;
        color:$white;
        font-size:1.9375rem;
        font-weight:300;
        text-transform:uppercase;
        padding:2rem 0;
        @include breakpoint(small only) {
            font-size:1.3125rem;
            padding:1.5rem 0;
        }
    }
    .ptext p{
        font-size:1.8125rem;
        font-weight:300;
        line-height:2.75rem;
        color:$dark-gray;
        margin:0;
        padding:0 0 2rem;
        @include breakpoint(small only) {
            font-size:1.1875rem;
        }
    }
    .callout{
        font-size:2.4375rem;
        line-height:1.23;
        color:$dark-gray;
        font-weight:800;
        padding:2.6rem 0;
        border-right:none;
        border-left:none;
        background:none;
        border-top:3px $purple solid;
        border-bottom:3px $purple solid;
        margin-bottom:2rem;
        @include breakpoint(small only) {
            font-size:1.875rem;
        }
    }
    .signoff{
        text-align:center;
        color:$secondary-color;
        font-size:2.625rem;
        font-weight:300;

        @include breakpoint(small only) {
            font-size:2rem;
        }
    }
    
    @include breakpoint(small only) {
        .heartdown{
            height:1118px;
            background:url(../img/about/line2.svg) center 730px no-repeat;
            background-size:3px 464px;
            img{
                margin-top:1rem;
            }
        }


    }
}
/*----------Share Buttons--------*/
.sharebox{
    text-align:center;
    margin-top:5rem;
    margin-bottom:6rem;
    .button{
        margin:1rem .5rem;
    }
    h3{
        font-size:2rem;
        @include breakpoint(small only) {
            font-size:1.875rem;
        } 
    }
}
/*----------for aboutpage and for submit story page --------*/
figure{
    display:flex;
    flex-flow:row wrap;

    img{
        max-width:65%;
    }

    figcaption{
        font-size:.8125rem;
        max-width:35%;
        //padding:0 .5rem;
        padding:0;
        font-style: italic;
    }

    &.reverse{
        flex-flow:row-reverse wrap;

        figcaption{
            text-align:right;
        }
    }
    &.small{
        display:inline-block;
        max-width:45%;
        width:45%;
        text-align:center;
        vertical-align: top;
        margin:0;
        padding:0;
        img{
            width:100%;
            max-width:100%;
            display:block;
            margin:0;
            padding:0;
            //vertical-align: top;
        }
        figcaption{
            text-align:center;
            max-width:100%;
            padding:0 .5rem;
        }
    }

    @include breakpoint(medium only) {
        figcaption{
            font-size:.5125rem;
        }
    }
    
    
}
div.figures{
    display:block;
    margin-top:2rem;
    text-align:center;
    padding:0 5%;
    width:100%;
}

/* our stories page-----------------------*/

.storypage {
    .blob.teal{
        top:50%;
    }
    .blob.purple2{
        bottom:3%;
    }
    h4{
        font-size:1.625rem;
        @include breakpoint(small only) {
            font-size:1.5rem;
        }
    }
    .astory{
        padding:9rem 0;
        @include breakpoint(small only) {
            padding:5rem 0;
        }
        background:url(../img/stories/line2.svg) right top no-repeat;
        background-size:50% 100%;
    }
    .tanbox{
        background:$tan;
        color:$black;
        padding:5rem 1rem;
    }
    .see{
        background:url(../img/stories/line3.svg) center top no-repeat;
        padding:4rem 0 7rem;
        @include breakpoint(small only) {
            padding:3rem 0 6rem;
            background-size:3px 620px;
        }
    }
    .quotecard{
        margin:0 1rem 2.5rem;
        position:relative;
        width:calc(33% - 2rem);
        display:inline-block;

        .text{
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            padding:1.125rem 1.125rem 3.5rem;
            color:$white;
        } 
        
        &.twoline{
            margin-left: 1.2rem;

            @include breakpoint(small only) {
                margin-left: 1rem;
            }
        }

        @include breakpoint(medium only) {
            width:calc(33% - 1rem);
            margin:0 .4rem 2rem;
        }

        @include breakpoint(small only) {
            width:calc(100% - 2rem);
        }
    }


}



/* our stories articles-----------------------*/
.page-trigger {
    margin-top: 8rem;
    margin-bottom:4rem;
}

.header_img{
    margin-bottom: 0.5rem;
}

.highlight{
    color:#9A5FA5;
    font-size: 40px;
    font-weight:600;
    line-height: 1.2em;
}

.storypage .callout{
    padding:0;
    border: none;
    max-width: 448px;

    &.float-right {
        margin: 0 0 1rem 1rem;
    }
}

.pullquote{
    margin-top: 1.5rem;
    border-top: 3px solid #9A5FA5;
    padding: 1.2rem 0 1.2rem 0;
    border-bottom: 3px solid #9A5FA5;

    p{
        font-size: 39px;
        font-weight: 700;
        font-style: italic;
        line-height: 1.2em;
    }
}


/* featured story-----------------------*/
.feature {
    h1{
        font-size:2.375rem;
        margin-bottom:2rem;
        @include breakpoint(small only) {
            font-size:1.875rem;
        }

    }
    .blob.teal{
        top:50%;
    }
    .blob.yellow{
        width:627px;
        height:627px;
        bottom:2%;
        left:auto;
        transform:rotate(180deg);
        @include breakpoint(small only) {
            right:93vw;
            width: 360px;
            height:360px;
            bottom:0;
        }
        @include breakpoint(medium ) {
            bottom:0;
            right:96vw;
        }
        @include breakpoint(large ) {
            right:94vw;
        }
        @include breakpoint(xlarge ) {
            right:93vw;
        }
        @include breakpoint(xxlarge ) {
            right:92vw;
        }
    }
    h1{
        margin-bottom:2.6875rem;
        @include breakpoint(small only) {
            margin-bottom:1.5rem;;
         }
    }
    .trigger{
        margin:6rem 0;
    }
    img{
        border-radius:6px;
        @include myshadow();
    }
    .picture-right{
        float:right;
        width:50%;
        margin:0 0 2rem 2rem;
        @include breakpoint(small only) {
            float:none;
            width:100%;
            margin:1rem 0 2.5rem;
         }
    }
    .topcontent{
       font-size: 1.625rem;
        line-height:1.4;
        padding-bottom:1rem;
        @include breakpoint(small only) {
            font-size:1.25rem;;
         }
    }
    p{
        font-size:1.1875rem;
        line-height:1.52;
        padding-bottom:1.5rem;
        @include breakpoint(small only) {
            font-size:1.125rem;
            padding-bottom:1.4rem;
         }

    }
}
/* submit story page-----------------------*/
.submit-story {

    h1{
        padding-top:8rem;
        padding-bottom:2rem;
        @include breakpoint(small only) {
            padding-top:3rem;
            font-size:2rem;
            padding-bottom:1rem;
        }
    }
    .topcontent{
        margin-top:3rem;
        @include breakpoint(small only) {
            margin-top:2rem;
        }
    }
    .heartdown{
        height:216px;
        background:url(../img/about/line2.svg) center -112px no-repeat;
        @include breakpoint(small only) {
            height:620px;
            width:100%;
            background:url(../img/about/line3.svg) center 160px no-repeat;
         }
    }
    .subform{
       // padding:3.5rem 2.875rem;
        background:$tan;
        border-radius:13px;
        color:$black;
        margin:3rem 0 7rem;
        @include breakpoint(small only) {
            padding:3rem 1.5rem;
            margin:2rem -1rem 4rem;
        }
    }
    .wFormContainer .wForm{
        background:$tan;
        color:$black;
    }
    .wFormContainer .label{
        background:none;
        color:rgb(15,28,54);
        font-family:"Open Sans", sans-serif;
        font-weight:700;
    }
    .wFormContainer .inputWrapper{
        width:100%;
    }
    .wFormContainer .wForm .inputWrapper input[type='text']{
        border-radius:6px !important;
        background:$white !important;
        border:1px $black solid !important;
        width:100% !important;
        height: 2rem !important;
    }
    .saveAndResume input[type='text'], .saveAndResume input[type='password'], .required:not(.choices):not(select):not(.wfAutosuggest) {
        font-family: 'Open Sans',sans-serif!important;
        color: $black !important;
        border: 1px solid #d8dde6!important;
        border-radius: 6px!important;
        font-size: 1rem!important;
        background-color: #fff!important;
        padding: .5rem !important;
        height: 2rem !important;
    }
    .wFormContainer .wForm .inputWrapper input[type='text'], .wFormContainer .wForm .inputWrapper input[type='password'], .wFormContainer .wForm .inputWrapper select, .wFormContainer .wForm .inputWrapper textarea {
        font-family: 'Open Sans',sans-serif;
        color: $black;
        border: 1px solid $black;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 1rem;
        width: 100%;
        background-color: #fff;
    }
    .wFormContainer .wForm .inputWrapper input:not([type="file"]):not(.tt-hint):read-only, .wFormContainer .wForm .inputWrapper textarea:read-only {
        font-family: 'Open Sans',sans-serif;
        background-color: #f3f2f2!important;
        border: 1px solid $black;
        color: $black;
    }
    .wFormContainer .wForm .inputWrapper .oneChoice input[type="checkbox"]{
        height: 2rem;
        margin-bottom: 10px;
    }
    #tfa_4-L{
        white-space: normal;
    }
    .wFormContainer .actions .primaryAction {
        background: #0F1C36;
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        border-radius: 34px;
        padding: 16px 37px 18px;
        text-transform: uppercase;
        -webkit-box-shadow: 0px 12px 11px 0px rgba(0, 0, 0, 0.18);
        box-shadow: 0px 12px 11px 0px rgba(0, 0, 0, 0.18);
    }
    .wFormContainer .actions {
        text-align:center;
    }
    .g-recaptcha > div{
        margin:0 auto;
    }
    label{
        font-size:1.1875rem;
        font-weight:700;
        margin:0;
        padding:0;
        width:auto;
        @include breakpoint(medium down) {
            font-size:1rem;
        }
    }
    .fminput{
        display:flex;
        flex-flow:row nowrap;
        align-items:baseline;
        align-content:stretch;
        justify-content:space-between;
        margin-bottom:2rem;
        @include breakpoint(small only) {
            flex-flow:row wrap;
        }

        > div{
            flex-grow: 6;
            @include breakpoint(small only) {
                width:100%;
            }
        }

        input{
            border-radius:8px;
            background:$white;
            border:1px $black solid;
            align-self:flex-end baseline;
            @include breakpoint(small only) {
                width:100%;
            }
        }
        label{
            display:inline-block;
            width: 6rem;
            white-space:normal;
        }
    }
     /* Checkbox */
    .fmcheck {
       /* display: block;

        display:flex;
        flex-flow:row nowrap;
        align-items:center;
        align-content:stretch;
        margin-bottom:2rem;*/

        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

    }

    /* Hide default checkbox */
    .fmcheck input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* custom checkbox */
    .checkmark {
        height: 42px;
        width: 42px ;
        display:block;
        background-color: $white;
        position:relative;
        border-radius:6px;
        border:1px $black solid;

        //align-self:flex-end center;
        //margin-left:12px;
    }

    /* hover color */
    .fmcheck:hover input ~ .checkmark {
        background-color: $light-gray;
    }   

    /* checked background  color*/
    .fmcheck input:checked ~ .checkmark {
        background-color: $primary-color;
    }

    /* indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* show when checked */
    .fmcheck input:checked ~ .checkmark:after {
        display: block;
    }

    /* checkmark indicator style */
    .fmcheck .checkmark:after {
        left: 15px;
        top: 5px;
        width: 12px;
        height: 24px;
        border: solid $black;
        border-width: 0 4px 4px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    } 
    textarea {
        width: 100%;
        height: 14rem;
        border: 1px $black solid;
        border-radius: 6px;
        background-color: $white;
        resize: none;
        margin-bottom:3rem;
    }
    input[type="submit"], #submitbtn{
        margin-bottom:none;
    }
    @include breakpoint(small only) {
        .heartdown{
            height:1109px;
            background:url(../img/about/line2.svg) center 749px no-repeat;
            background-size:3px 406px;
            img{
                margin-top:1rem;
            }
        }
    }
}
/* thank you form success page-----------------------*/
.thankyoupage{
    .blob.orange{
        top:auto;
        bottom:-30px;
    }
}

/* understanding depression page-----------------------*/

.types-of-depression {
    .blob.yellow{
        bottom:2%;
    }
    .arrowdownpage{
        height:192px;
        background:url(../img/types/line2.svg) center -10px no-repeat;
       a{
            position:absolute;
            left:0;
            bottom:0;
        }
    
    }
    #info{
        position:relative;
        margin-top:3rem;

    }
    .large{
        font-size:1.5625rem;
        line-height:1.3;
        strong{
            font-size:1.875rem;
            color:black;
            font-weight:700;
        }
    }
    #info::after{
        content:"";
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:81%;
        z-index:-1;
        background:$tan;
        
        
    }
    .facts{
        background:url(../img/types/line3.svg) top right no-repeat;
        background-size:50% 100%;
        padding:16rem 0 24rem;
        @include breakpoint(large up) {
            padding:9rem 0 15rem;
        }
    }
    
    .factcard{
        padding: 0 2rem;
        border-right:1px #D9D9D9 solid;
        margin-bottom:2rem;

        p{
            font-size:1.1875rem;
            line-height:1.5;
        }
        .img-con{
            text-align:center;
            display:flex;
            flex-flow:row wrap;
            width:100%;
            height:190px;
            align-items:center;
            justify-content:center;
            font-family:"Poppins";
            font-size:4.3rem;
            line-height:1;
            font-weight:700;
            color:$secondary-color;
            padding:0;margin:0;

            span{
                font-size:1.5625rem;
                text-transform: uppercase;
                line-height: 1.5rem;
                display:block;
                width:100%;
            }
        }
        
    }

    .iwrap .cell:nth-child(3n){
       .factcard{
            @include breakpoint(large up) {
                border-right:none;
            }

        }
    }

    .iwrap .cell:nth-child(2n){
        .factcard{
             @include breakpoint(medium only) {
                border-right:none;
            }
 
        }
    }

    .iwrap .cell{
        .factcard{
            @include breakpoint(small only) {
                border-right:none;
                padding:0;
                border-top:1px #D9D9D9 solid;
            }

            .img-con{
                @include breakpoint(small only) {
                    height:160px;
                }
                img{
                    @include breakpoint(small only) {
                        transform:scale(.75);
                    }
                }

            }
 
        }
    }
    .iwrap .cell:nth-child(1){
        .factcard{
             @include breakpoint(small only) {
                border-top:none;
            }
 
        }
    }

    .vibox.end{
        h3{
            font-size:1.875rem;
            @include breakpoint(small only) {
                font-size:1.1875rem;
            }
        }
    }
}
/* treatment-options page-----------------------*/

.treatment-options {
    

    .psy{
        padding:16rem 0;
        background:url(../img/treat/line2.svg) top left no-repeat;
        background-size:50% 100%;
        @include breakpoint(small only) {
            padding:8rem 0;
        }
    }

    ul{
        list-style:none;
        margin:1.625rem 0;
        padding:0;


        li{
            font-size:1.125rem;
            color:$dark-gray;
            line-height:1.75rem;
            margin-bottom:1.625rem;
            position:relative;
            display:block;
            padding-left:3rem;
        }
        li:before{
            content:"";
            width:30px;
            height:30px;
            top:0;
            left:0;
            display:block;
            position:absolute;
            background:url(../img/treat/icon-list.svg) 0 0 no-repeat;
        }
    }
    .med-section{
        background:$primary-color;
        color:#333333;
        padding:8rem 0 8rem;
        ul li{
            color:#333333;
        }
        @include breakpoint(small only) {
            padding:4rem 0;
        }
        p{
            color:#333333;
        }
    }
    .subtitle{
        color:$black;
        font-weight:600;
        font-size:1.375rem;
        line-height:1.45;
        padding:2rem 3rem;
    }
    .ect{
        padding:14rem 0;
        background:url(../img/treat/line3.svg) top left no-repeat;
        background-size:50% 100%;
        @include breakpoint(small only) {
            padding:7rem 0;
        }

    }
    .tms{
        padding:3rem 0;
        @include breakpoint(small only) {
            padding:2rem 0;
        }
    }
}

.labelimg{
    position:relative;

    .clabel{
        width:auto;
        background:$white;
        display:inline-block;
        color:$black;
        font-size:.9375rem;
        padding:.75rem 2.25rem;
        border-top-right-radius:1.5rem 50%;
        border-bottom-right-radius:1.5rem 50%;
        text-transform:uppercase;
        position: absolute;
        left: 1.5rem;
        top: 1.6rem;
        margin-right: 3rem;
    }
}

/*--- card -------------------------*/
.tancard{background:$tancard;}
.vicard{background:$vicard;}
.greencard{background:$greencard;}
.greycard{background:$greycard;}
.browncard{background:$tan;}
.dkgreycard{background:$dkgreycard;}
.orangecard{background:$orangecard;}
.sgreencard{background:$sgreencard;}
.pinkcard{background:$pinkcard;}
.purplecard{background:$purplecard;}
.yellowcard{background:#FFFAE6;}


.dcard{
    display:block;
    position:relative;
    cursor:pointer;
    @include myshadow;
    height:100%;

    .clabel{
        width:auto;
        background:$white;
        display:inline-block;
        color:$black;
        font-size:.9375rem;
        padding:.75rem 2.25rem;
        border-top-right-radius:1.5rem 50%;
        border-bottom-right-radius:1.5rem 50%;
        text-transform:uppercase;

        &.rd2{
            border-top-right-radius:2rem 50%;
            border-bottom-right-radius:2rem 50%;

        }

        &.short{
            max-width:60%;
        }
    }
    h4{
        font-size:1.375rem;
        font-weight:700;
        color:$black;
        display:block;
        @include breakpoint(small only) {
            font-size:1.125rem;
        }
    }
    p{
        @include breakpoint(small only) {
            font-size:1rem;
        }
    }
    .dcard-link{
        position:absolute;
        font-size:.9375rem;
        font-weight:600;
        text-transform: uppercase;
        padding:.6rem 3rem 0 0;
        right:2rem; bottom:2rem;
        width:auto; height:40px;
        background:url(../img/arrow-go.svg) right  center no-repeat;
        color:$primary-color;
    }
    .img-con{
        display:flex;
        align-items:center;
        justify-content: center;
        width: 100%;
    }
    
    .csection{
        padding:1.375rem 2rem 4rem;
    }

    .overlink{
        position:absolute;
        left:0; top:0;
        width:100%;
        height:100%;
        cursor: pointer;
    }

}

.dcard.tool{
    min-height:514px;
    background:$white;

    
    .clabel{
        position:absolute;
        left:0; top:1.6rem;
        margin-right:3rem;
    }

    
    .img-con{
        //height:55%;
       // min-height:282px;
        height:282px;
        padding-top:1.5rem;

        img{
            max-height:75%
        }
    }
}

/* resource page -----------------------*/

.resourcepage {

    position:relative;
    margin-top:-80px;
    padding-top:80px;
    overflow:hidden;

    .blob.orange{
        top:440px;
        @include breakpoint(small only) {
            top:10%;
        }
    }
    .blob.purple2{
        @include breakpoint(small only) {
            right:94vw;
        }
        @include breakpoint(medium ) {
            right:95vw;
        }
        @include breakpoint(large ) {
            right:94vw;
        }
        @include breakpoint(xlarge ) {
            right:93vw;
        }
        @include breakpoint(xxlarge ) {
            right:92vw;
        }
    }
    
    .blob.yellow{
        @include breakpoint(small only) {
            left: 94vw;
        }
        @include breakpoint(medium ) {
            left:94vw;
        }
    
        @include breakpoint(large ) {
            left:93vw;
        }
        @include breakpoint(xlarge up) {
            left:92vw;
        }
    }

    
    .topcontent{
        margin-bottom:2rem;
    }
    
    .menu{
        background:$primary-color;
        margin-top:2.75rem;
        @include breakpoint(small only) {
            padding-top:1rem;
            padding-bottom:1rem;
        }

        a{
            color:$black;
            font-family:$header-font-family;
            font-weight:$header-font-weight;
            font-size:1.25rem;
            text-transform: uppercase;
            padding-top:2.5rem;
            padding-bottom:2.5rem;
            @include breakpoint(small only) {
                padding-top:1rem;
                padding-bottom:1rem;
            }
        }
    }
    .arrowdown{
        position:relative;
        margin:auto;
        width:98px;
        height:174px;
        background:url(../img/line2.svg) center -70px no-repeat;

        a{
            position:absolute;
            left:0;
            bottom:0;
        }

    }

    a.button.downarrow{
        width:98px;
        height:98px;
        background:url(../img/circlednarrow.svg) center center no-repeat;
    }

    h2{
        padding-bottom:1rem;
    }
    
    

    .cardcell{
        width:calc(33.3% - 2rem);
        margin:0 1rem 2.5rem;
        @include breakpoint(medium only) {
            width:calc(50% - 2rem);
        }
        @include breakpoint(small only) {
            //width:calc(33.3% - 2rem);
            width:calc(100% - 2rem);
        }

    }

    .cardholder.tabcon{
        justify-content:flex-start;
        border:none;
    }
    

    .dcard.hcdir{
        min-height:366px;
        height:100%;

        
        .clabel{
            margin-top:1.6rem;
            margin-right:3rem;
            display:inline-block;
        }

        .csection{
            padding:1.375rem 2rem 3rem;
        }
        
    }
    /*---------------tools -----------------*/
    #tools{
        background:url(../img/resources/line-2.svg) 0 0 no-repeat;
        background-size:50% 100%; 
    }

    .tabs.tools{
        border:none;
        background:none;
        border-bottom:1px #d9d9d9 solid;
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:stretch;

        li {
            position:relative;
            a{
                background:$primary-color;
                color:$black;
                font-size:1.375rem;
                font-weight:700;
                padding:1rem 2rem;
                margin:0 .5rem;
                height:100%;
                @include breakpoint(small only) {
                    font-size:.875rem;
                    padding:1rem 1.1rem;
                    margin:0 .3rem;
                }
            }
        }
        li.tabs-title.is-active::after{
            content:"";
            position:absolute;
            bottom:-24px;
            left:calc(50% - 22px);
            width: 0;
            height: 0;
            border-left: 18px solid transparent;
            border-right: 18px solid transparent;
            border-top: 25px solid $primary-color;
            @include breakpoint(small only) {
                left:calc(50% - 18px);
            }
        }
    }
    .tabs-panel, .tabs-content{
        border:none;
        padding:0;
        background:none;
    }

    

    /*----support cards----------------------------------*/
    .cardholder.support{
        justify-content:center;
        border:none;
        
        .cardcell{
            &.istop{
                width:calc(50%);
                margin:0 20% 2.5rem;
                @include breakpoint(medium only) {
                    width:calc(75%);
                    margin:0 1rem 2.5rem;
                }
                @include breakpoint(small only){
                    width:calc(100% - 2rem);
                    margin:0 1rem 2.5rem;
                }
            }
            @include breakpoint(small only) {
                width:calc(100% - 2rem);
            }
        }
    }

    div.supcard{
        min-height:326px;
        height:100%;
        padding:2rem;

        p{
            padding-bottom:5.5rem;
        }

        .labelblock{
            position:absolute;
            left:0; bottom:2rem;
            pointer-events:none;
            .slabel{
                margin-right:3rem;
                width:auto;
                background:$white;
                display:block;
                color:$black;
                font-size:.9375rem;
                padding:.75rem 2.25rem;
                border-top-right-radius:1.5rem 50%;
                border-bottom-right-radius:1.5rem 50%;
                text-transform:uppercase;
                pointer-events: auto;
                font-weight:600;
                &.chat{
                    background:$primary-color;
                    color:$white;
                    font-weight:600;
                    display:none;
                    pointer-events:none;
                }
                &.chat.show{
                     display:block;
                }
                &.chat.islink{
                    display:block;
                    pointer-events:auto;
               }
                a{
                     display:inline;
                     color:$black;
                }
            }
        }
        
    }
    .cardcell.istop div.supcard{
        @include breakpoint(medium up) {
            min-height:229px;
        }
    }
    
    /*----advocacy groups----------------------------------*/
    #groups{
        background:url(../img/resources/line-3.svg) 0 0 no-repeat;
        background-size:50% 100%; 
    }

    .cardholder.groups{
        justify-content:center;
        border:none;
        
        .cardcell{
            width:calc(25% - 2rem);
            @include breakpoint(small only) {
                width:calc(100% - 2rem);
            }
            @include breakpoint(medium only) {
                width:calc(50% - 2rem);
            }
        }
        .cardcell.mleft{
            @include breakpoint(large up) {
                margin-left:10%;
            }
        }
        .cardcell.mright{
            @include breakpoint(large up) {
                margin-right:10%;
            }
        }
    }

    div.groupcard{
        min-height:347px;
        height:100%;
        background:$white;
        padding:0;

        .img-con{
            height:170px;
            background:#FAF8F8;
            img{
                max-height:90%;//75%;
                width:auto;
            }
        }

        h4{
            margin:.8rem 1rem 0;
            padding-bottom:4rem;
        }
    }
        


}

/*------- modal   --------------------*/

.reveal.dllm{
    width:898px;
    max-width:100%;
    border-radius:13px;
    text-align:center;
    padding:6rem 7rem;
    @include breakpoint(medium only) {
        padding:4rem;
    }
    @include breakpoint(small only) {
        padding:4rem 2rem;
    }

    h1{
        font-size:2rem;
        span.webtitle{
            font-size:2.1875rem;
            color:$secondary-color;
        }
        @include breakpoint(small only) {
            font-size:1.75rem;
            span.webtitle{
                font-size:calc(100vw/20);
            }
        }
    }

    #linktitle{
        color:$black;
        font-weight:600;
    }
    .blpill{
        min-width:170px;
        margin:2rem 1.25rem;
        @include breakpoint(small only) {
            margin:1rem;
        }
    }
}

#formSuccessModal{
    .script{
        text-align:center;
        padding:0;
        margin-bottom:-1rem;
        @include breakpoint(small only) {
            line-height:.8;
            margin:1rem 0;
        }
    }
    h1{
        max-width:480px;
        text-align:center;
        margin:0 auto 1rem;
    }
}

#treatmentModal{
    span.msg{
        display:none;

        &.show{
            display:block;
        }
    }
}
/*------- footer   --------------------*/
.grid-container.fluid.footer{
    padding-left:0px;
    padding-right:0px;
    overflow:hidden;

    .intentxt p{
        font-style: italic;
        @include breakpoint(small only) {
            font-size:.75rem;
            line-height:1.5;
        }
    }

    .homepar{
        padding-bottom:3rem;
        @include breakpoint(small only) {
            padding-bottom:1rem;
        }
    }
    .people{
        overflow:hidden;
        //min-height:200px;
        img{
                width: 100%;
                height: 100%;
                object-fit: cover;
        }
    }

    .bgyellow{
        padding-top:6rem;
        padding-bottom:6rem;
        color:$black;

        h2{
            font-size:2.5rem;
            @include breakpoint(small only) {
                font-size:2.25rem;
            }

        }

        p{
            font-size:1.375rem;
            line-height: 2.185rem;
            color:$black;
            @include breakpoint(small only) {
                font-size:1.125rem;
                line-height: 1.625rem;
            }
        }
        a:link, a:visited, a:hover {
            color:$black;
        }
    }

    .photoinfo{
        color:$white;
        background:$dark-gray;
        text-align:center;
        p{
            color:$white;
        }
    }

    .bgblack{
        color:$white;
        font-size:.6875rem;
        padding-top:2rem;
        padding-bottom:3rem;

        .jlogo{
            width:185px;
            height:56px;
            background:url(../img/logo-janssen-neuro-white.png) 0 0 no-repeat;
            background-size:contain;
            margin-bottom:1.25rem;
            margin-top:.75rem;
        }
        a{
            color:$white;
            font-weight:bold;
            text-decoration:none;
            font-size:.6875rem;
            
        }
        a.nobold{font-weight:normal;}
        a.toplink{
            display:inline-block;
            margin-bottom:.75rem;
            @include breakpoint(small only) {
                display:block;
            }
        }

        a.toplink.mid{
            border-left:1px $white solid;
            border-right:1px $white solid;
            padding:0 .5rem;
            @include breakpoint(small only) {
                padding-left:0;
                border:none;
            }
        }
        a.toplink.plh{
            @include breakpoint(small only){
                padding-left:0;
            }
        }
    
        a.underline{
            text-decoration:underline;
            font-size:.6875rem;
        }
    
        p{
            font-size:.6875rem;
            line-height:.9375rem;
            color:$white;
        }

        .frow{
            position:relative;
            .uparrow{
                position:absolute;
                top:0;
                right:20px;
            }
        }
    }
}

/*------- trigger   --------------------*/
.trigger{
    background: $dark-gray;
    color:white;
    font-size:1.125rem;
    padding:1rem 2rem;
    line-height:1.5;

    strong.nobr{
        color:$primary-color;
    }

    @include breakpoint(small only) {
        padding:1rem;
        font-size:1rem;
    }

}
/*------- references   --------------------*/

.references{

    p{
        padding:0 0 3px 12px;
        text-indent:-12px;
        font-size:.8125rem;
        line-height:1.538;
        margin:0 0 .3rem;
        span{
            display:inline-block;
            width:1rem;
            text-align:right;
            padding-right:2px;
        }
    }

    h5{
        font-size:1.25;
        color:$black;
        padding:0;
        margin-bottom:.8rem;
    }
    margin-bottom:6rem;
}